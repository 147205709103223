import styled from 'styled-components';
import {
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography,
  compose,
} from 'styled-system';

const styleProps = compose(
  background,
  border,
  color,
  flexbox,
  grid,
  layout,
  position,
  shadow,
  space,
  typography,
);

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const Link = styled.a<any>`
  ${styleProps}

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-indent: ${(props) => props.textIndent};
  text-transform: ${(props) => (props.textTransform || 'none')};
  font-style: ${(props) => props.fontStyle || 'normal'};
  white-space: ${(props) => props.whiteSpace || 'normal'};
  opacity: ${(props) => props.opacity || '1'};
  box-sizing: ${(props) => props.boxSizing || 'content-box'};
  text-decoration: ${(props) => (props.textDecoration || 'none')};
  transition: ${(props) => props.transition || 'none'};

  &:hover {
    cursor: pointer;
    color: ${(props) => props.color || props?.theme?.colors?.wagGreen || 'blue'};
    ${(props) => props.hoverColor && `
      color: ${props.hoverColor};
    `}
  }
  ${(props) => props.disabled && `
    cursor: default;
    pointer-events: none;
  `}
`;

Link.defaultProps = {
  href: '#',
};

export { Link };
